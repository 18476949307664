import React from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { filter, findIndex, orderBy } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import {
  Chunk,
  Wrapper,
  Person,
  PersonLink,
  Name,
  Title,
  IconWrapper,
  TextWrapper,
} from './styles'

const orderTeam = people => {
  console.log(people)
  const claireIndex = findIndex(people, {
    node: { id: '3aa34894-2631-511e-b7af-85097b5f146f' },
  })
  people.splice(0, 0, people.splice(claireIndex, 1)[0])

  const erinIndex = findIndex(people, {
    node: { id: '81c63dba-efed-525f-90c5-05a2fca73204' },
  })
  people.splice(0, 0, people.splice(erinIndex, 1)[0])

  const saraIndex = findIndex(people, {
    node: { id: 'dace11ef-30c5-5c27-bdf4-7d1ccab00f3b' },
  })
  people.splice(0, 0, people.splice(saraIndex, 1)[0])

  const michelineIndex = findIndex(people, {
    node: { id: 'a3828af4-68bd-5f59-9530-5af4a7e634a7' },
  })
  people.splice(0, 0, people.splice(michelineIndex, 1)[0])

  const jenIndex = findIndex(people, {
    node: { id: '1d197eed-3625-567d-a9a3-91b4bba679fc' },
  })
  people.splice(people.length, 0, people.splice(jenIndex, 1)[0])

  const trevorIndex = findIndex(people, {
    node: { id: '227b6059-438f-5b5d-a54d-5a065e35229a' },
  })
  people.splice(people.length, 0, people.splice(trevorIndex, 1)[0])

  return people
}

const Content = ({ people }) => (
  <div>
    <Wrapper bg="white" color="black">
      <Chunk>
        <h3>NASP Team</h3>
        <div>
          {orderTeam(
            filter(
              people,
              o =>
                o.node.group === 'Team' &&
                o.node.id !== 'c8e4a049-bc2d-5991-a584-6a102ec5a70c'
            )
          ).map(person =>
            person.node.bio ||
            person.node.image ||
            person.node.linkWebsite ||
            person.node.linkTwitter ||
            person.node.linkInstagram ? (
              <PersonLink
                to={`/collaborators/${slugify(person.node.name, {
                  lower: true,
                })}`}
                key={person.node.id}
              >
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.title}</Title>
                </TextWrapper>

                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              </PersonLink>
            ) : (
              <Person key={person.node.id}>
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.title}</Title>
                </TextWrapper>
              </Person>
            )
          )}
        </div>
      </Chunk>
      <Chunk>
        <h3>Artists & Writers</h3>
        <div>
          {orderBy(
            filter(people, o => o.node.group === 'Artist'),
            [p => p.node.name.toLowerCase()],
            ['asc']
          ).map(person =>
            person.node.bio ||
            person.node.image ||
            person.node.linkWebsite ||
            person.node.linkTwitter ||
            person.node.linkInstagram ? (
              <PersonLink
                to={`/collaborators/${slugify(person.node.name, {
                  lower: true,
                })}`}
                key={person.node.id}
              >
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.field}</Title>
                </TextWrapper>

                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              </PersonLink>
            ) : (
              <Person key={person.node.id}>
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.field}</Title>
                </TextWrapper>
              </Person>
            )
          )}
        </div>
      </Chunk>
      <Chunk>
        <h3>Contributors</h3>
        <div>
          {orderBy(
            filter(people, o => o.node.group === 'Contributor'),
            [p => p.node.name.toLowerCase()],
            ['asc']
          ).map(person =>
            person.node.bio ||
            person.node.image ||
            person.node.linkWebsite ||
            person.node.linkTwitter ||
            person.node.linkInstagram ? (
              <PersonLink
                to={`/collaborators/${slugify(person.node.name, {
                  lower: true,
                })}`}
                key={person.node.id}
              >
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.field}</Title>
                </TextWrapper>

                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              </PersonLink>
            ) : (
              <Person key={person.node.id}>
                <TextWrapper>
                  <Name>{person.node.name}</Name>
                  <Title>{person.node.field}</Title>
                </TextWrapper>
              </Person>
            )
          )}
        </div>
      </Chunk>
    </Wrapper>
  </div>
)

export default Content
