import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import ChangeTitle from 'containers/change-title'
import Head from '../components/Pages/Collaborators/Head'
import Content from '../components/Pages/Collaborators/Content'

import config from '../../data/SiteConfig'

const CollaboratorsPage = ({ data }) => {
  // changeTitle('Collaborators')

  return (
    <div className="collaborators-container">
      <Helmet title={`Collaborators | ${config.siteTitle}`} />
      <ChangeTitle title="Collaborators" />
      <Head />
      <Content people={data.allContentfulCollaborator.edges} />
    </div>
  )
}

export default CollaboratorsPage

/* eslint no-undef: "off" */
export const query = graphql`
  query CollaboratorQuery {
    allContentfulCollaborator {
      edges {
        node {
          id
          title
          name
          field
          group
          bio {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          linkWebsite
          linkTwitter
          linkInstagram
        }
      }
    }
  }
`
